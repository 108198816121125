import axios from "axios";
import router from "@/router";
import { message } from "ant-design-vue";
import NProgress from "nprogress";

const host = process.env.VUE_APP_API_HOST;
const imgHost = process.env.VUE_APP_IMG_HOST;
const baseURL = `${host}/api`;

//附件基础路径
const imageBaseUrl = `${imgHost}/`;

const apiClient = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const veryEngineApiClient = axios.create({
  baseURL: "https://utp.veryengine.cn",
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

const downloadApiClient = axios.create({
  baseURL,
  withCredentials: false,
  responseType: "blob",
});

downloadApiClient.interceptors.request.use((config) => {
  NProgress.start();
  let token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

//如果服务端返回401,则跳转到登录页面
downloadApiClient.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    NProgress.done();
    if (error.response.status == 401) {
      this.$store.state.isMyPage = true;
      router.replace({ name: "Login" });
    } else {
      message.error("出错了");
      return Promise.reject(error);
    }
  }
);

//在请求发出前Header中加上 token
apiClient.interceptors.request.use((config) => {
  if (config.url !== "/messages/unread-message-exists") {
    NProgress.start();
  }
  let token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

//如果服务端返回401,则跳转到登录页面
apiClient.interceptors.response.use(
  (response) => {
    NProgress.done();

    let d = response.data;
    if (d.code == 50000) {
      message.error(d.message);
    }
    return response;
  },
  (error) => {
    NProgress.done();
    if (error.response.status == 401) {
      this.$store.state.isMyPage = true;
      router.push({ name: "Login" });
    } else if (error.response.status == 400) {
      message.error("请求错误，请稍后再试");
      return Promise.reject(error);
    } else if (error.response.status == 404) {
      message.error("请求的网页网页不存在，请稍后再试");
      return Promise.reject(error);
    } else if (error.response.status == 500) {
      message.error("服务器错误，请稍后再试");
      return Promise.reject(error);
    } else if (error.response.status == 502) {
      message.error("服务器无响应，请稍后再试");
      return Promise.reject(error);
    } else if (error.response.status == 503) {
      message.error("服务器暂时无法使用，请稍后再试");
      return Promise.reject(error);
    } else {
      message.error("网络错误，请稍后再试");
      return Promise.reject(error);
    }
  }
);

export { apiClient, downloadApiClient, baseURL, imageBaseUrl, veryEngineApiClient };

export function genDownloadUrl(relativePath) {
  return imageBaseUrl + relativePath;
}
