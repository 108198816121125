<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view v-if="!$route.meta.keepAlive" />
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  mounted() {},
  methods: {
    // goRelation() {
    //   scroll({
    //     top: this.$refs.footBar.$el.offsetTop,
    //     behavior: "smooth",
    //   });
    // },
  },
};
</script>

<style>
#app {
  background: rgba(0, 0, 0, 0.75);
  min-width: 1200px;
}
.container {
  min-width: 1200px;
  min-height: calc(100vh - 72px);
}

</style>
